<template>
  <cookie-law theme="soulmedicine"></cookie-law>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

export default {
  components: { CookieLaw },
};
</script>
