import Vue from 'vue/dist/vue.esm';

import Rollbar from 'rollbar';

// See https://docs.rollbar.com/docs/vue-js#vue-2 for more details.

Vue.prototype.$rollbar = new Rollbar({
  accessToken: "020467d9907c400bb89e82138cb92495",
  environment: "production",
  enabled: true,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: { log: false }
});

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};
